import React from "react";
import * as Styles from "./IconStyles";

interface IconProps {
  iconImage: string;
  iconColor?: string;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({
  iconImage,
  iconColor = "#000",
  className,
}) => {
  return (
    <Styles.Icon
      iconImage={iconImage}
      iconColor={iconColor}
      className={`icon-${iconImage} ${className || ""}`}
    />
  );
};

export default Icon;
