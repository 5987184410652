import React from "react";
import styled from "styled-components";
import { BREAK_POINTS } from "../../../lib/breakPoints";

const ContainerWrapper = styled("div")<{
  $fluid?: string;
}>`
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${({ $fluid }) => ($fluid ? "1440px" : "1280px")};
  max-width: ${({ $fluid }) => $fluid === "maxFluid" && "100%"};

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    width: ${({ $fluid }) => ($fluid ? "100%" : "1000px")};
  }
  /* Tablet: max-width: 1000px */
  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    max-width: ${({ $fluid }) => ($fluid ? "100%" : "100%")};
  }
`;

interface ContainerStyleProps {
  className?: string;
  style?: React.CSSProperties;
  fluid?: "true" | "maxFluid";
  id?: string;
  children?: React.ReactNode;
}

const ContainerStyle: React.FC<ContainerStyleProps> = ({
  className,
  style,
  fluid,
  id,
  children,
}) => {
  return (
    <ContainerWrapper
      id={id}
      style={style}
      className={className}
      $fluid={fluid}
    >
      {children}
    </ContainerWrapper>
  );
};
export default ContainerStyle;
